import { Helmet } from "react-helmet-async";
import vzImg from "images/contact-vz.jpg";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionPromo1 from "components/SectionPromo1";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  clearData,
  getList as onGetList,
   updateData as onUpdateData,
  addNewData as onAddNewData,
} from "store/AppMaster/actions";
import api from "constants/api";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import React, { FC,Fragment, useState,useEffect } from "react";

export interface PageContactProps {
  className?: string;
}

const info = [
  {
    title: "Corporate Office & Retails :",
    desc: "#205, 2nd Floor, T-Square, Chandivali Junction,Saki Vihar Road, Saki Naka,Andheri East, Mumbai – 400072. India.",
  },
  {
    title: "EMAIL",
    desc: "sales@vijaydeep.in",
  },
  {
    title: "Mobile Number",
    desc: "9082677909",
  },
  {
    title: "TEL",
    desc: "+91-22 6107 7900",
  },
  
];

const pune = [
  {
    title: "Branch Office: Pune",
    desc: "F-11/12, Vishweshwar Ind. Premises(VIP Estate),PCNTDA, Bhosari,Pune – 411026. India",
  },
  {
    title: "EMAIL",
    desc: "pune@vijaydeep.in",
  },
  {
    title: "Mobile Number",
    desc: "7066512020",
  },
  {
    title:"TEL",
    desc: "+91-020-27130015",
  },
 
];
const ahmedabad = [
  {
    title: "Branch Office: Ahmedabad",
    desc: "No. 18, Shiv Shakti Estate,Near Gujarat Bottling, Rakhial,Ahmedabad 380023. India",
  },
  {
    title: "EMAIL",
    desc: "ahmedabad@vijaydeep.in",
  },
  {
    title: "TEL",
    desc: "+91-79 2277 0189",
  },

];
const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  const [frmData,setFrmData] = useState({
      name:"",
      email:"",
      mobile:"",
      message:""
  });
  const dispatch = useDispatch();

  const onChangeHandler =(event:any) =>{
    const {name,value}  =event.target;
    setFrmData((preValue)=>{
        return {
            ...preValue,
            [name]:value,
        };
    });
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
        name: (frmData && frmData.name) || "",
        email: (frmData && frmData.email) || "",
        mobile: (frmData && frmData.mobile) || "",
        message: (frmData && frmData.message) || ""
    },
    validationSchema: Yup.object({
        name: Yup.string().required("Please Enter Name").matches(
            /^[A-Za-z ]+$/i,
            "Must be a valid Name"
        ).max(50, "Must be less than  50 characters"),
        email: Yup.string().required("Please Enter Email").matches(
          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            "Must be a valid Email"
        ),
        mobile: Yup.string().required("Please Enter Contact No").matches(
          /^[+0-9]+$/i,
          "Please Enter Valid Contact No"
        ).min(9, "Please Enter Valid Contact No").max(12, "Please Enter Valid Contact No"),
        message: Yup.string().required("Please Enter Message")
    }),
    onSubmit: values => {
        const newData = {
            name: values["name"],
            email: values["email"],
            mobile: values["mobile"],
            message: values["message"]
        };
        dispatch(onAddNewData(newData, "", api.frontContactEnquiry))
        setFrmData((preValue)=>{
            return {
                ...preValue,
                name:"",
                email:"",
                mobile:"",
                message:""
            };
        });
    },
  });

  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact Us | Vijaydeep</title>
      </Helmet>
      <div className="">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact Us
        </h2>
        <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="shadow-lg p-5 rounded-lg gap-4 grid">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-1 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              {/* <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 SOCIALS
                </h3>
                <SocialsList className="mt-2" />
              </div> */}
            </div>
            {/* <div className="shadow-lg p-5 rounded-lg gap-3 grid">
              {sakinaka.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
            </div> */}
            <div className="shadow-lg p-5 rounded-lg gap-3 grid">
              {pune.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
            </div>
            <div className="shadow-lg p-5 rounded-lg gap-3 grid">
              {ahmedabad.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
            </div>
          </div>
          <div className="grid grid-cols-1 mt-20">
          <h2 className="text-2xl md:text-4xl font-semibold text-center">Send us a message</h2>
          </div>
          <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 mb-20 items-center bg-slate-50 lg:p-12 p-5 rounded-2xl">
            <div>
            <img className="w-full rounded-lg" src={vzImg} alt="" />
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6"
                onSubmit={e => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                method="post"
               >
                <label className="block">
                  <Label>Full name</Label>
                  <Input
                    placeholder="Full Name"
                    type="text"
                    name="name"
                    className="mt-1"
                    value={frmData.name}
                    onChange={onChangeHandler}
                  />
                   {validation.errors.name ? (
                        <small className="text-rose-600">{validation.errors.name}</small>
                    ) : null}
                </label>
                <label className="block">
                  <Label>Email address</Label>

                  <Input
                    type="email"
                    name="email"
                    value={frmData.email}
                    placeholder="example@example.com"
                    className="mt-1"
                    onChange={onChangeHandler}
                  />
                   {validation.errors.email ? (
                    <small className="text-rose-600">{validation.errors.email}</small>
                ) : null}
                </label>
                <label className="block">
                  <Label>Contact No</Label>

                  <Input
                    type="text"
                    name="mobile"
                    value={frmData.mobile}
                    placeholder=""
                    className="mt-1"
                    onChange={onChangeHandler}
                  />
                   {validation.errors.mobile ? (
                    <small className="text-rose-600">{validation.errors.mobile}</small>
                ) : null}
                </label>
                <label className="block">
                  <Label>Message</Label>

                  <Textarea name="message" className="mt-1" value={frmData.message} rows={6} onChange={onChangeHandler} />
                  {validation.errors.message ? (
                    <small className="text-rose-600">{validation.errors.message}</small>
                ) : null}
                </label>
                <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* OTHER SECTIONS */}
      {/* <div className="container">
        <div className="relative my-24 lg:my-32 py-24 lg:py-32">
          <BackgroundSection />
          <SectionPromo1 />
        </div>
      </div> */}
    </div>
  );
};

export default PageContact;
