import React, { FC, useEffect, useState, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import NcImage from "shared/NcImage/NcImage";
import { useDispatch, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";
import imageRightJpg from "images/Injection-Accessories.jpg";
import {
  getList as onGetList,
  updateData as onUpdateData,
  addNewData as onAddNewData,
  getData as onGetData,
  clearData
} from "store/AppMaster/actions";
import api from "constants/api";
import { RootState } from 'store/reducers';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from "@mui/material";
import moment from 'moment';



export interface ViewProps {
  data: any;
  result: any[]
}

interface HeaderRow {
  // Define the properties of your header row here
  [key: string]: string;
}

interface BodyRow {
  // Define the properties of your body row here
  [key: string]: string;
}

const View: FC<ViewProps> = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [isApiCall, setIsApiCall] = useState(0);
  const [groupData, setGroupData] = useState(false);
  const [inqueryData,setInquiryData] = useState({
    id:"",
    name:"",
    email:"",
    mobile:"",
    message:"",
    createdAt:""
});


  useEffect(() => {
    if (isApiCall == 0) {
      getGroupDetails();
      setIsApiCall(1);
    }
  }, [isApiCall]);

  const { data } = useSelector((state: RootState) => state.AppMaster);

  const getGroupDetails = () => {
    setGroupData(true);
    let filterData = {
      id: params.id
    }
    dispatch(onGetData(filterData, '', api.contactInquiryDetail));
  }

  if (data.contact!= null && groupData == true) {
    setGroupData(false);
    setInquiryData((preValue)=>{
      return {
          ...preValue,
          id:data.contact._id,
          name:data.contact.name,
          email:data.contact.email,
          mobile:data.contact.mobile,
          message:data.contact.message,
          createdAt:data.contact.createdAt
      };
    });
  }

  return (
    <div className="container py-16 lg:pb-28 lg:pt-20">
      <Helmet>
        <title>Contact Inquiry Details</title>
      </Helmet>
      <div className="mb-12 sm:mb-16">
        <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">Contact Inquiry Details</h2>
      </div>
      <div className="mt-8 sm:mt-10">
        <div className="listingSectionSidebar__wrap shadow-lg border-0 p-8 rounded-2xl mb-8 sm:mb-10">
          <div className="flex items-center">
            <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
              <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 grid-cols-1 gap-4">
                <div className="flex items-center">
                  <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Name :</h5>
                  <h6 className="font-semibold">{inqueryData && inqueryData.name}</h6>
                </div>
                <div className="flex xl:items-center items-start">
                  <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Email :</h5>
                  <h6 className="font-semibold break-all">{inqueryData && inqueryData.email}</h6>
                </div>
                <div className="flex xl:items-center items-start">
                  <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Contact No :</h5>
                  <h6 className="font-semibold break-all">{inqueryData && inqueryData.mobile}</h6>
                </div>
                <div className="flex md:items-start items-start">
                  <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Created At :</h5>
                  <h6 className="font-semibold">{inqueryData && inqueryData.createdAt && moment(inqueryData.createdAt).format('DD-MM-YYYY hh:mm A')}</h6>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-4 mt-3">
                  <div className="md:flex items-center">
                    <h5 className="text-sm text-slate-700 mr-2 whitespace-nowrap">Message :</h5>
                    <h6 className="font-semibold">{inqueryData && inqueryData.message}</h6>
                  </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(View);
